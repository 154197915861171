export default function Error404() {
    return (
        <div class="bg-white py-6 sm:py-8 lg:py-12">
            <div class="max-w-screen-2xl px-4 md:px-8 mx-auto">
                <div class="flex flex-col items-center">
                <a href="/" alt="Byma">
                    <img
                        src="https://res.cloudinary.com/htkavmx5a/image/upload/c_scale,f_auto,h_120,q_auto:good/v1596650832/byma_new_logo_2_i7nwf4.png"
                        className="mb-9 w-16"
                        alt="Byma Logo"
                    />
                </a>

                    <p class="text-red-500 text-sm md:text-base font-semibold uppercase mb-4">Erro 404</p>
                    <h1 class="text-gray-800 text-2xl md:text-3xl font-bold text-center mb-2">Página não encontrada</h1>

                    <p class="max-w-screen-md text-gray-500 md:text-lg text-center mb-12">A página que você está buscando não existe.</p>

                    <a href="/" class="btn btn-primary">Ir para a Home</a>
                </div>
            </div>
        </div>
    )
}
